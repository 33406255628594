import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Paragraph } from '../Paragraph';
import { Box } from '../Box';
import { Link, LinkStyled, LinkUnstyled } from './';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "link"
    }}>{`Link`}</h1>
    <h2 {...{
      "id": "core-components"
    }}>{`Core Components`}</h2>
    <p>{`There are four basic link components:`}</p>
    <p><inlineCode parentName="p">{`LinkStyled`}</inlineCode>{` and `}<inlineCode parentName="p">{`LinkUnstyled`}</inlineCode>{` are rendering as standard `}<inlineCode parentName="p">{`a`}</inlineCode>{` tags.
`}<inlineCode parentName="p">{`RoutedLinkStyled`}</inlineCode>{` and `}<inlineCode parentName="p">{`RoutedLinkUnstyled`}</inlineCode>{` are rendering as `}<inlineCode parentName="p">{`react-router-dom/Link`}</inlineCode></p>
    <p>{`To trigger navigation within the app, use a `}<inlineCode parentName="p">{`RoutedLink`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`Styled`}</inlineCode>{` links have an underline, where as unstyled links will inherit the styles from it's parent, but also accepts basic styled-system props.`}</p>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`RoutedLink`}</inlineCode>{` and `}<inlineCode parentName="p">{`RoutedLinkUnstyled`}</inlineCode>{` need `}<inlineCode parentName="p">{`react-router-dom`}</inlineCode>{` as a peer dependency. So it will pick up withever version of react-router-dom you're using in the app through the react context API.
This is to avoid version clashes. Hoppin Design System does not bundle `}<inlineCode parentName="p">{`react-router-dom`}</inlineCode>{`, it's up to you to provide it in your app as a dependency.`}</p>
    </blockquote>
    <p>{`All demos here use the un-routed versions because react-router-dom `}<inlineCode parentName="p">{`<LinkUnstyled/>`}</inlineCode>{` can't be rendendered outside a `}<inlineCode parentName="p">{`<Router>`}</inlineCode>{` component.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Routed (internal) Links use react-router-dom-style `}<inlineCode parentName="p">{`to`}</inlineCode>{` props, rather than HTML style href.`}<br />{`
Unrouted (external) Links use `}<inlineCode parentName="p">{`href`}</inlineCode>{`, they are simple `}<inlineCode parentName="p">{`a`}</inlineCode>{` tags.
All other props are the same between routed and unrouted links.`}</p>
    </blockquote>
    <h2 {...{
      "id": "magic-links"
    }}>{`Magic Links`}</h2>
    <p>{`The above core components are great for readability of the code and to make explicit decisions, but can be difficult to work with when you're rendering dynamic links.
the `}<inlineCode parentName="p">{`<Link />`}</inlineCode>{` component is a convenience-wrapper around the four components above and will automagically render the appropriate link.`}</p>
    <p><inlineCode parentName="p">{`<Link to='/my/app/route'>`}</inlineCode>{` will render as `}<inlineCode parentName="p">{`<RoutedLinkStyled to='/my/app/route'>`}</inlineCode></p>
    <p><inlineCode parentName="p">{`<Link unstyled={true} to='/my/app/route'>`}</inlineCode>{` will render as `}<inlineCode parentName="p">{`<RoutedLinkUnstyled to='/my/app/route'>`}</inlineCode></p>
    <p><inlineCode parentName="p">{`<Link to='http://example.com/'>`}</inlineCode>{` will render as `}<inlineCode parentName="p">{`<LinkStyled href='http://example.com/'>`}</inlineCode></p>
    <p><inlineCode parentName="p">{`<Link unstyled={true} to='http://example.com/'>`}</inlineCode>{` will render as `}<inlineCode parentName="p">{`<LinkStyled href='http://example.com/'>`}</inlineCode></p>
    <Playground __position={0} __code={'<Link to=\"http://example.com/\" target=\"_blank\">\n  Magic Styled Link\n</Link>\n<br />\n<Link unstyled={true} to=\"http://example.com/\" target=\"_blank\">\n  Magic Unstyled Link\n</Link>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Paragraph,
      Box,
      Link,
      LinkStyled,
      LinkUnstyled,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Link to="http://example.com/" target="_blank" mdxType="Link">
    Magic Styled Link
  </Link>
  <br />
  <Link unstyled={true} to="http://example.com/" target="_blank" mdxType="Link">
    Magic Unstyled Link
  </Link>
    </Playground>
    <Props of={Link} mdxType="Props" />
    <h2 {...{
      "id": "styled-link-routed-and-unrouted"
    }}>{`Styled Link (Routed and Unrouted)`}</h2>
    <p>{`This is your standard link. It can have the same `}<inlineCode parentName="p">{`context`}</inlineCode>{` prop as `}<a parentName="p" {...{
        "href": "/components/Button"
      }}>{`Button`}</a>{` components.`}</p>
    <Props of={LinkUnstyled} mdxType="Props" />
    <Playground __position={3} __code={'<LinkStyled>Click me</LinkStyled>\n<LinkStyled context=\"shadower\">shadower</LinkStyled>\n<LinkStyled context=\"host\">Host</LinkStyled>\n<LinkStyled context=\"danger\">Danger</LinkStyled>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Paragraph,
      Box,
      Link,
      LinkStyled,
      LinkUnstyled,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <LinkStyled mdxType="LinkStyled">Click me</LinkStyled>
  <LinkStyled context="shadower" mdxType="LinkStyled">
    shadower
  </LinkStyled>
  <LinkStyled context="host" mdxType="LinkStyled">
    Host
  </LinkStyled>
  <LinkStyled context="danger" mdxType="LinkStyled">
    Danger
  </LinkStyled>
    </Playground>
    <h2 {...{
      "id": "links-in-context"
    }}>{`Links in context`}</h2>
    <p>{`Link are displayed as `}<inlineCode parentName="p">{`inline-block`}</inlineCode>{` by default, so they nest in to any body copy, like the `}<a parentName="p" {...{
        "href": "/components/Paragraph"
      }}>{`Paragraph`}</a>{`.`}</p>
    <Playground __position={4} __code={'<Paragraph>\n  Lorem ipsum dolor amet photo booth scenester cornhole trust fund vaporware\n  williamsburg. Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom\n  locavore, kogi everyday carry af tattooed art party asymmetrical cardigan\n  sustainable. Tbh cornhole post-ironic, literally hashtag ethical adaptogen\n  brooklyn bushwick distillery. <LinkStyled>Click me</LinkStyled> vapes\n  stumptown swag glossier small batch gastropub. Taxidermy 90\'s everyday carry\n  kombucha. Banjo VHS occupy marfa roof party slow-carb.\n</Paragraph>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Paragraph,
      Box,
      Link,
      LinkStyled,
      LinkUnstyled,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Paragraph mdxType="Paragraph">
    Lorem ipsum dolor amet photo booth scenester cornhole trust fund vaporware
    williamsburg. Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom
    locavore, kogi everyday carry af tattooed art party asymmetrical cardigan
    sustainable. Tbh cornhole post-ironic, literally hashtag ethical adaptogen
    brooklyn bushwick distillery. <LinkStyled mdxType="LinkStyled">Click me</LinkStyled> vapes
    stumptown swag glossier small batch gastropub. Taxidermy 90's everyday carry
    kombucha. Banjo VHS occupy marfa roof party slow-carb.
  </Paragraph>
    </Playground>
    <h2 {...{
      "id": "unstyled-links"
    }}>{`Unstyled Links`}</h2>
    <p>{`Unstyled Links are identical with the normal link components, but remove any stuyling. No underline, no hover state.`}</p>
    <Props of={LinkUnstyled} mdxType="Props" />
    <Playground __position={6} __code={'<Paragraph>\n  Lorem ipsum dolor amet photo booth scenester cornhole trust fund vaporware\n  williamsburg. Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom\n  locavore, kogi everyday carry af tattooed art party asymmetrical cardigan\n  sustainable. Tbh cornhole post-ironic, literally hashtag ethical adaptogen\n  brooklyn bushwick distillery.{\' \'}\n  <LinkUnstyled href=\"https://gohoppin.com\" target=\"_blank\">\n    Find me, click me\n  </LinkUnstyled>{\' \'}\n  vapes stumptown swag glossier small batch gastropub. Taxidermy 90\'s everyday\n  carry kombucha. Banjo VHS occupy marfa roof party slow-carb.\n</Paragraph>\n<Box padding=\"large\" bg=\"neutral.light\">\n  <LinkUnstyled href=\"https://gohoppin.com\" target=\"_blank\">\n    <Box margin=\"large\" padding=\"large\" bg=\"white\">\n      Clickable Box\n    </Box>\n  </LinkUnstyled>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Paragraph,
      Box,
      Link,
      LinkStyled,
      LinkUnstyled,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Paragraph mdxType="Paragraph">
    Lorem ipsum dolor amet photo booth scenester cornhole trust fund vaporware
    williamsburg. Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom
    locavore, kogi everyday carry af tattooed art party asymmetrical cardigan
    sustainable. Tbh cornhole post-ironic, literally hashtag ethical adaptogen
    brooklyn bushwick distillery. <LinkUnstyled href='https://gohoppin.com' target="_blank" mdxType="LinkUnstyled">Find me, click me</LinkUnstyled> vapes stumptown
    swag glossier small batch gastropub. Taxidermy 90's everyday carry kombucha.
    Banjo VHS occupy marfa roof party slow-carb.
  </Paragraph>
  <Box padding='large' bg='neutral.light' mdxType="Box">
  <LinkUnstyled href='https://gohoppin.com' target="_blank" mdxType="LinkUnstyled">
    <Box margin='large' padding='large' bg='white' mdxType="Box">
      Clickable Box
    </Box>
    </LinkUnstyled>
  </Box>
    </Playground>
    <p>{`LinkUnstyled takes a `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop.
It also accepts basic `}<inlineCode parentName="p">{`display`}</inlineCode>{` and `}<inlineCode parentName="p">{`flexbox (child)`}</inlineCode>{` props and can be used like a `}<inlineCode parentName="p">{`Box`}</inlineCode>{` that links to something.`}</p>
    <Playground __position={7} __code={'<Paragraph>\n  Lorem ipsum dolor amet photo booth scenester cornhole trust fund vaporware\n  williamsburg. Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom\n  locavore, kogi everyday carry af tattooed art party asymmetrical cardigan\n  sustainable. Tbh cornhole post-ironic, literally hashtag ethical adaptogen\n  brooklyn bushwick distillery.{\' \'}\n  <LinkUnstyled\n    href=\"https://gohoppin.com\"\n    target=\"_blank\"\n    color=\"neutral.light\"\n  >\n    Find me, click me\n  </LinkUnstyled>{\' \'}\n  vapes stumptown swag glossier small batch gastropub. Taxidermy 90\'s everyday\n  carry kombucha. Banjo VHS occupy marfa roof party slow-carb.\n</Paragraph>\n<Box padding=\"large\" bg=\"neutral.light\">\n  <LinkUnstyled\n    href=\"https://gohoppin.com\"\n    target=\"_blank\"\n    margin=\"large\"\n    padding=\"large\"\n    bg=\"white\"\n  >\n    Clickable Box\n  </LinkUnstyled>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Paragraph,
      Box,
      Link,
      LinkStyled,
      LinkUnstyled,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Paragraph mdxType="Paragraph">
    Lorem ipsum dolor amet photo booth scenester cornhole trust fund vaporware
    williamsburg. Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom
    locavore, kogi everyday carry af tattooed art party asymmetrical cardigan
    sustainable. Tbh cornhole post-ironic, literally hashtag ethical adaptogen
    brooklyn bushwick distillery. <LinkUnstyled href='https://gohoppin.com' target="_blank" color='neutral.light' mdxType="LinkUnstyled">Find me, click me</LinkUnstyled> vapes stumptown
    swag glossier small batch gastropub. Taxidermy 90's everyday carry kombucha.
    Banjo VHS occupy marfa roof party slow-carb.
  </Paragraph>
  <Box padding='large' bg='neutral.light' mdxType="Box">
    <LinkUnstyled href='https://gohoppin.com' target="_blank" margin='large' padding='large' bg='white' mdxType="LinkUnstyled">
      Clickable Box
    </LinkUnstyled>
  </Box>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      